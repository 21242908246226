@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --left-nav-width: 260px;
  --action-bar-height: 46px;
  --top-nav-height: 54px;
  --modal-top-bar-height: 53px;
}

@layer base {
  html {
    @apply h-full bg-grey-1;

    body {
      @apply h-full overflow-hidden text-sm text-default-text;

      &.app-loaded {
        .app-loading-screen {
          @apply hidden;
        }

        cc-root {
          @apply flex;

          height: inherit;
        }
      }
    }
  }

  th {
    @apply text-left;
  }

  input[type='radio'],
  input[type='checkbox'] {
    @apply cursor-pointer;

    &:disabled {
      @apply cursor-not-allowed;
    }
  }
}

@layer components {
  /*
    This class matches the styles of a list written in plain text in Figma with our current Design System.
    We might need to make this part of a plugin that handles rich text in the future. (e.g. tailwwindcss/typography).
  */
  .fs-unordered-list {
    @apply list-inside list-none pl-2;

    & li::before {
      content: '•';
      @apply me-2;
    }
  }

  /*
    This is a temporary class to style the date input field in the form.
    We will remove this in favour of using a date picker/input component when we have it defined in the design system.
    TODO: find and replace all instances of this class with the date picker component when it is available.
  */
  .fs-temp-input-date {
    @apply h-[36px] w-[200px] rounded p-2 outline-none ring-2 ring-inset ring-grey-3 focus:ring-default-blue;
    &:disabled {
      @apply cursor-not-allowed bg-grey-1 text-default-text;
    }
  }

  // TODO: Remove this when the shared toast component follows a proper z-index strategy
  .toast-container {
    @apply z-[2001] pointer-events-none;

    div {
      @apply pointer-events-auto;
    }
  }
}

.animate-fade-in {
  animation: fade-in 0.5s;
  animation-fill-mode: both;
}

@keyframes fade-in {
  0% {
    @apply opacity-0;
  }

  100% {
    @apply opacity-100;
  }
}
